/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/CryoAdvance 3D Web File Concept.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[8.4, 0, 8.5]}>
        <group position={[0.9, 2.3, 0.9]} rotation={[-Math.PI, 1.3, Math.PI / 2]}>
          <mesh
            geometry={nodes['XWM30-02_web_model_jason_4'].geometry}
            material={nodes['XWM30-02_web_model_jason_4'].material}
          />
        </group>
        <group position={[-0.9, 2.3, -0.9]} rotation={[-Math.PI, -1.4, Math.PI / 2]}>
          <mesh
            geometry={nodes['XSM30-48_web_model_jason_4'].geometry}
            material={nodes['XSM30-48_web_model_jason_4'].material}
          />
        </group>
        <group position={[-0.9, 2.3, -0.9]} rotation={[0, -1.3, -Math.PI / 2]}>
          <mesh
            geometry={nodes['XWM30-02_web_model_jason_5'].geometry}
            material={nodes['XWM30-02_web_model_jason_5'].material}
          />
        </group>
        <group position={[0.9, 2.3, 0.9]} rotation={[0, 1.4, -Math.PI / 2]}>
          <mesh
            geometry={nodes['XSM30-48_web_model_jason_5'].geometry}
            material={nodes['XSM30-48_web_model_jason_5'].material}
          />
        </group>
        <group position={[-0.9, 2.3, 0.9]} rotation={[0, 0.3, -Math.PI / 2]}>
          <mesh
            geometry={nodes['XWM30-02_web_model_jason_6'].geometry}
            material={nodes['XWM30-02_web_model_jason_6'].material}
          />
        </group>
        <group position={[-0.9, 2.3, 0.9]} rotation={[0, -0.2, -Math.PI / 2]}>
          <mesh
            geometry={nodes['XSM30-48_web_model_jason_6'].geometry}
            material={nodes['XSM30-48_web_model_jason_6'].material}
          />
        </group>
        <group position={[1.4, 7.3, 0]} rotation={[0, Math.PI / 2, 0]}>
          <mesh
            geometry={nodes['4400-2019_web_model_jason_1'].geometry}
            material={nodes['4400-2019_web_model_jason_1'].material}
          />
        </group>
        <group position={[0.9, 2.3, -0.9]} rotation={[-Math.PI, -0.3, Math.PI / 2]}>
          <mesh
            geometry={nodes['XWM30-02_web_model_jason_7'].geometry}
            material={nodes['XWM30-02_web_model_jason_7'].material}
          />
        </group>
        <group position={[0, 7.3, 1.4]}>
          <mesh
            geometry={nodes['4100-2611_web_model_jason_1'].geometry}
            material={nodes['4100-2611_web_model_jason_1'].material}
          />
        </group>
        <group position={[-1.6, 5.4, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes['4100-033_web_model_jason_5'].geometry}
            material={nodes['4100-033_web_model_jason_5'].material}
          />
        </group>
        <group position={[0, 7.2, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes['4100-210_web_model_jason_1'].geometry}
            material={nodes['4100-210_web_model_jason_1'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_2'].geometry}
            material={nodes['4100-210_web_model_jason_2'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_3'].geometry}
            material={nodes['4100-210_web_model_jason_3'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_4'].geometry}
            material={nodes['4100-210_web_model_jason_4'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_5'].geometry}
            material={nodes['4100-210_web_model_jason_5'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_6'].geometry}
            material={nodes['4100-210_web_model_jason_6'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_7'].geometry}
            material={nodes['4100-210_web_model_jason_7'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_8'].geometry}
            material={nodes['4100-210_web_model_jason_8'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_9'].geometry}
            material={nodes['4100-210_web_model_jason_9'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_10'].geometry}
            material={nodes['4100-210_web_model_jason_10'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_11'].geometry}
            material={nodes['4100-210_web_model_jason_11'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_12'].geometry}
            material={nodes['4100-210_web_model_jason_12'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_13'].geometry}
            material={nodes['4100-210_web_model_jason_13'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_14'].geometry}
            material={nodes['4100-210_web_model_jason_14'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_15'].geometry}
            material={nodes['4100-210_web_model_jason_15'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_16'].geometry}
            material={nodes['4100-210_web_model_jason_16'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_17'].geometry}
            material={nodes['4100-210_web_model_jason_17'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_18'].geometry}
            material={nodes['4100-210_web_model_jason_18'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_19'].geometry}
            material={nodes['4100-210_web_model_jason_19'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_20'].geometry}
            material={nodes['4100-210_web_model_jason_20'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_21'].geometry}
            material={nodes['4100-210_web_model_jason_21'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_22'].geometry}
            material={nodes['4100-210_web_model_jason_22'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_23'].geometry}
            material={nodes['4100-210_web_model_jason_23'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_24'].geometry}
            material={nodes['4100-210_web_model_jason_24'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_25'].geometry}
            material={nodes['4100-210_web_model_jason_25'].material}
          />
          <mesh
            geometry={nodes['4100-210_web_model_jason_26'].geometry}
            material={nodes['4100-210_web_model_jason_26'].material}
          />
        </group>
        <group position={[0, 5.4, 1.6]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
          <mesh
            geometry={nodes['4100-033_web_model_jason_6'].geometry}
            material={nodes['4100-033_web_model_jason_6'].material}
          />
        </group>
        <group position={[0, 5.4, -1.6]} rotation={[-Math.PI / 2, 0.2, 0]}>
          <mesh
            geometry={nodes['4100-033_web_model_jason_7'].geometry}
            material={nodes['4100-033_web_model_jason_7'].material}
          />
        </group>
        <group position={[0, 7.2, 0]} rotation={[0, Math.PI / 2, 0]}>
          <mesh
            geometry={nodes['4100-033_web_model_jason_8'].geometry}
            material={nodes['4100-033_web_model_jason_8'].material}
          />
        </group>
        <group position={[0, 4.6, 0]} rotation={[0, Math.PI / 4, 0]}>
          <mesh
            geometry={nodes['4405-2014_web_model_jason_1'].geometry}
            material={nodes['4405-2014_web_model_jason_1'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_2'].geometry}
            material={nodes['4405-2014_web_model_jason_2'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_3'].geometry}
            material={nodes['4405-2014_web_model_jason_3'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_4'].geometry}
            material={nodes['4405-2014_web_model_jason_4'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_5'].geometry}
            material={nodes['4405-2014_web_model_jason_5'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_6'].geometry}
            material={nodes['4405-2014_web_model_jason_6'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_7'].geometry}
            material={nodes['4405-2014_web_model_jason_7'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_8'].geometry}
            material={nodes['4405-2014_web_model_jason_8'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_9'].geometry}
            material={nodes['4405-2014_web_model_jason_9'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_10'].geometry}
            material={nodes['4405-2014_web_model_jason_10'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_11'].geometry}
            material={nodes['4405-2014_web_model_jason_11'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_12'].geometry}
            material={nodes['4405-2014_web_model_jason_12'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_13'].geometry}
            material={nodes['4405-2014_web_model_jason_13'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_14'].geometry}
            material={nodes['4405-2014_web_model_jason_14'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_15'].geometry}
            material={nodes['4405-2014_web_model_jason_15'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_16'].geometry}
            material={nodes['4405-2014_web_model_jason_16'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_17'].geometry}
            material={nodes['4405-2014_web_model_jason_17'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_18'].geometry}
            material={nodes['4405-2014_web_model_jason_18'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_19'].geometry}
            material={nodes['4405-2014_web_model_jason_19'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_20'].geometry}
            material={nodes['4405-2014_web_model_jason_20'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_21'].geometry}
            material={nodes['4405-2014_web_model_jason_21'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_22'].geometry}
            material={nodes['4405-2014_web_model_jason_22'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_23'].geometry}
            material={nodes['4405-2014_web_model_jason_23'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_24'].geometry}
            material={nodes['4405-2014_web_model_jason_24'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_25'].geometry}
            material={nodes['4405-2014_web_model_jason_25'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_26'].geometry}
            material={nodes['4405-2014_web_model_jason_26'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_27'].geometry}
            material={nodes['4405-2014_web_model_jason_27'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_28'].geometry}
            material={nodes['4405-2014_web_model_jason_28'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_29'].geometry}
            material={nodes['4405-2014_web_model_jason_29'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_30'].geometry}
            material={nodes['4405-2014_web_model_jason_30'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_31'].geometry}
            material={nodes['4405-2014_web_model_jason_31'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_32'].geometry}
            material={nodes['4405-2014_web_model_jason_32'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_33'].geometry}
            material={nodes['4405-2014_web_model_jason_33'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_34'].geometry}
            material={nodes['4405-2014_web_model_jason_34'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_35'].geometry}
            material={nodes['4405-2014_web_model_jason_35'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_36'].geometry}
            material={nodes['4405-2014_web_model_jason_36'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_37'].geometry}
            material={nodes['4405-2014_web_model_jason_37'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_38'].geometry}
            material={nodes['4405-2014_web_model_jason_38'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_39'].geometry}
            material={nodes['4405-2014_web_model_jason_39'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_40'].geometry}
            material={nodes['4405-2014_web_model_jason_40'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_41'].geometry}
            material={nodes['4405-2014_web_model_jason_41'].material}
          />
          <mesh
            geometry={nodes['4405-2014_web_model_jason_42'].geometry}
            material={nodes['4405-2014_web_model_jason_42'].material}
          />
        </group>
        <group position={[-1.5, 0.4, -1.5]} rotation={[0, Math.PI / 4, 0]}>
          <group position={[-0.6, 0.3, -1.9]} rotation={[-Math.PI, -0.5, 0]}>
            <mesh
              geometry={nodes['MDP-07_simplified_web_model_jason_2'].geometry}
              material={nodes['MDP-07_simplified_web_model_jason_2'].material}
            />
            <mesh
              geometry={nodes['MDP-07_simplified_web_model_jason_3'].geometry}
              material={nodes['MDP-07_simplified_web_model_jason_3'].material}
            />
          </group>
          <group position={[0, 0, -13.1]} rotation={[0, Math.PI / 2, 0]}>
            <group position={[3.7, 18.6, -3.3]} rotation={[0, 0.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['FH-M6-15ZI_simplified_web_model_jason_8'].geometry}
                material={nodes['FH-M6-15ZI_simplified_web_model_jason_8'].material}
              />
            </group>
            <group position={[-8, 11.1, 2.1]} rotation={[-Math.PI, -0.7, -Math.PI]}>
              <mesh
                geometry={nodes['PEM_S-M4_Nut_simplified_web_model_jason_4'].geometry}
                material={nodes['PEM_S-M4_Nut_simplified_web_model_jason_4'].material}
              />
            </group>
            <group position={[3.7, 18.6, 3.3]} rotation={[0, 0.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['FH-M6-15ZI_simplified_web_model_jason_9'].geometry}
                material={nodes['FH-M6-15ZI_simplified_web_model_jason_9'].material}
              />
            </group>
            <group position={[-4.7, 18.6, 3.9]} rotation={[0, 0.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['FH-M6-15ZI_simplified_web_model_jason_10'].geometry}
                material={nodes['FH-M6-15ZI_simplified_web_model_jason_10'].material}
              />
            </group>
            <group position={[-4.7, 18.6, -3.9]} rotation={[0, 0.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['FH-M6-15ZI_simplified_web_model_jason_11'].geometry}
                material={nodes['FH-M6-15ZI_simplified_web_model_jason_11'].material}
              />
            </group>
            <group position={[-8, 11.1, -2.1]} rotation={[-Math.PI, -0.7, -Math.PI]}>
              <mesh
                geometry={nodes['PEM_S-M4_Nut_simplified_web_model_jason_5'].geometry}
                material={nodes['PEM_S-M4_Nut_simplified_web_model_jason_5'].material}
              />
            </group>
            <group position={[-8.8, 11.1, 0]} rotation={[-Math.PI, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['4134-2005-1_simplified_web_model_jason_2'].geometry}
                material={nodes['4134-2005-1_simplified_web_model_jason_2'].material}
              />
            </group>
            <group position={[8, 17.6, 3.3]} rotation={[0.2, 0, 3]}>
              <mesh
                geometry={nodes['PEM_SS-M5-2ZI_simplified_web_model_jason_4'].geometry}
                material={nodes['PEM_SS-M5-2ZI_simplified_web_model_jason_4'].material}
              />
            </group>
            <group position={[0, 18.6, 0]}>
              <mesh
                geometry={nodes['4400-2018_simplified_web_model_jason_3'].geometry}
                material={nodes['4400-2018_simplified_web_model_jason_3'].material}
              />
            </group>
            <group position={[8, 17.6, 2.9]} rotation={[-Math.PI / 2, -1.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['4134-2005-2_simplified_web_model_jason_4'].geometry}
                material={nodes['4134-2005-2_simplified_web_model_jason_4'].material}
              />
            </group>
            <group position={[8, 17.6, -3.3]} rotation={[0.2, 0, 3]}>
              <mesh
                geometry={nodes['PEM_SS-M5-2ZI_simplified_web_model_jason_5'].geometry}
                material={nodes['PEM_SS-M5-2ZI_simplified_web_model_jason_5'].material}
              />
            </group>
            <group position={[8, 17.6, -2.9]} rotation={[Math.PI / 2, 1.4, -Math.PI / 2]}>
              <mesh
                geometry={nodes['4134-2005-2_simplified_web_model_jason_5'].geometry}
                material={nodes['4134-2005-2_simplified_web_model_jason_5'].material}
              />
            </group>
          </group>
          <group position={[0, 0.8, -1.2]} rotation={[-Math.PI, 0, Math.PI]}>
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_1'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_1'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_2'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_2'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_3'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_3'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_4'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_4'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_5'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_5'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_6'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_6'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_7'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_7'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_8'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_8'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_9'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_9'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_10'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_10'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_11'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_11'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_12'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_12'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_13'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_13'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_14'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_14'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_15'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_15'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_16'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_16'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_17'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_17'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_18'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_18'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_19'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_19'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_20'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_20'].material}
            />
            <mesh
              geometry={nodes['4100-225_simplified_web_model_jason_21'].geometry}
              material={nodes['4100-225_simplified_web_model_jason_21'].material}
            />
          </group>
          <group position={[0, 0.8, -1.2]} rotation={[0, 0, Math.PI]}>
            <group position={[0.6, 0.6, 0.2]} rotation={[0, 0, Math.PI / 6]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_8'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_8'].material}
              />
            </group>
            <group position={[0.6, -0.6, 1.1]} rotation={[-Math.PI, 0, -Math.PI / 3]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_9'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_9'].material}
              />
            </group>
            <group rotation={[0, 0, Math.PI / 2]}>
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_2'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_2'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_3'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_3'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_4'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_4'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_5'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_5'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_6'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_7'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_7'].material}
              />
            </group>
            <group position={[0, 0, 1.3]} rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_8'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_8'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_9'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_9'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_10'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_10'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_11'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_11'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_12'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_12'].material}
              />
              <mesh
                geometry={nodes['4200-223_simplified_web_model_jason_13'].geometry}
                material={nodes['4200-223_simplified_web_model_jason_13'].material}
              />
            </group>
            <group position={[0, 0, 0.6]} rotation={[0, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_1'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_1'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_2'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_2'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_3'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_3'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_4'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_4'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_5'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_5'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_6'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_7'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_7'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_8'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_8'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_9'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_9'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_10'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_10'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_11'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_11'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_12'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_12'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_13'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_13'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_14'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_14'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_15'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_15'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_16'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_16'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_17'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_17'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_18'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_18'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_19'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_19'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_20'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_20'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_21'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_21'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_22'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_22'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_23'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_23'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_24'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_24'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_25'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_25'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_26'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_26'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_27'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_27'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_28'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_28'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_29'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_29'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_30'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_30'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_31'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_31'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_32'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_32'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_33'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_33'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_34'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_34'].material}
              />
              <mesh
                geometry={nodes['4200-219_simplified_web_model_jason_35'].geometry}
                material={nodes['4200-219_simplified_web_model_jason_35'].material}
              />
            </group>
            <group position={[-0.6, 0.6, 0.2]} rotation={[0, 0, Math.PI / 6]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_10'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_10'].material}
              />
            </group>
            <group position={[0.6, -0.6, 0.2]} rotation={[0, 0, -Math.PI / 3]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_11'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_11'].material}
              />
            </group>
            <group position={[-0.6, -0.6, 0.2]} rotation={[0, 0, Math.PI / 3]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_12'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_12'].material}
              />
            </group>
            <group position={[-0.6, -0.6, 1.1]} rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_8'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_8'].material}
              />
            </group>
            <group position={[0.6, 0.6, 1.1]} rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_9'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_9'].material}
              />
            </group>
            <group position={[0.6, 0.6, 1.1]} rotation={[-Math.PI, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_13'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_13'].material}
              />
            </group>
            <group position={[0.6, -0.6, 1.1]} rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_10'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_10'].material}
              />
            </group>
            <group position={[0.6, -0.6, 0.1]} rotation={[-Math.PI, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_11'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_11'].material}
              />
            </group>
            <group position={[-0.6, 0.6, 1.1]} rotation={[-Math.PI, 0, Math.PI]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_14'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_14'].material}
              />
            </group>
            <group position={[-0.6, -0.6, 1.1]} rotation={[-Math.PI, 0, -Math.PI / 3]}>
              <mesh
                geometry={nodes['XSM30-35_simplified_web_model_jason_15'].geometry}
                material={nodes['XSM30-35_simplified_web_model_jason_15'].material}
              />
            </group>
            <group position={[0.6, 0.6, 0.1]} rotation={[-Math.PI, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_12'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_12'].material}
              />
            </group>
            <group position={[-0.6, 0.6, 1.1]} rotation={[-Math.PI, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_13'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_13'].material}
              />
            </group>
            <group position={[-0.6, -0.6, 0.1]} rotation={[-Math.PI, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_14'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_14'].material}
              />
            </group>
            <group position={[-0.6, 0.6, 0.1]} rotation={[-Math.PI, -Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['XWM30-02_simplified_web_model_jason_15'].geometry}
                material={nodes['XWM30-02_simplified_web_model_jason_15'].material}
              />
            </group>
          </group>
          <group position={[0, -0.4, 4.7]} rotation={[0, Math.PI / 2, 0]}>
            <mesh
              geometry={nodes['4134-2000_simplified_web_model_jason_1'].geometry}
              material={nodes['4134-2000_simplified_web_model_jason_1'].material}
            />
          </group>
          <group position={[0, -0.5, 2.1]} rotation={[-Math.PI / 2, 0, Math.PI / 4]}>
            <group position={[1.8, 0.9, 2.1]} rotation={[0, 0, -1.6]}>
              <mesh
                geometry={nodes['XSM20-05_simplified_web_model_jason_4'].geometry}
                material={nodes['XSM20-05_simplified_web_model_jason_4'].material}
              />
            </group>
            <group position={[1.1, -1.6, 2.1]} rotation={[0, 0, -1.6]}>
              <mesh
                geometry={nodes['XSM20-05_simplified_web_model_jason_5'].geometry}
                material={nodes['XSM20-05_simplified_web_model_jason_5'].material}
              />
            </group>
            <group position={[0.9, 1.9, 2.1]} rotation={[0, 0, 0.1]}>
              <mesh
                geometry={nodes['XSM20-05_simplified_web_model_jason_6'].geometry}
                material={nodes['XSM20-05_simplified_web_model_jason_6'].material}
              />
            </group>
            <group position={[-0.4, -1.6, 1.3]} rotation={[0, Math.PI / 2, 0]}>
              <mesh
                geometry={nodes['VV028-01_SIDEPANEL_simplified_web_model_jason_2'].geometry}
                material={nodes['VV028-01_SIDEPANEL_simplified_web_model_jason_2'].material}
              />
            </group>
            <group position={[3.9, 3.9, 0.5]}>
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_1'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_1'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_2'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_2'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_3'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_3'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_4'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_4'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_5'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_5'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_6'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_7'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_7'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_8'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_8'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_9'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_9'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_10'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_10'].material}
              />
              <mesh
                geometry={nodes['4106-224_simplified_web_model_jason_11'].geometry}
                material={nodes['4106-224_simplified_web_model_jason_11'].material}
              />
            </group>
            <group
              position={[1.8, 1.8, 2.1]}
              rotation={[-Math.PI / 2, Math.PI / 4, -Math.PI]}
              scale={[1, 1, 1]}>
              <mesh
                geometry={nodes['4134-2034_simplified_web_model_jason_1'].geometry}
                material={nodes['4134-2034_simplified_web_model_jason_1'].material}
              />
            </group>
            <group position={[0, 0, 2.1]}>
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_1'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_1'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_2'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_2'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_3'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_3'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_4'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_4'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_5'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_5'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_6'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_7'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_7'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_8'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_8'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_9'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_9'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_10'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_10'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_11'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_11'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_12'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_12'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_13'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_13'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_14'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_14'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_15'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_15'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_16'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_16'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_17'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_17'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_18'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_18'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_19'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_19'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_20'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_20'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_21'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_21'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_22'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_22'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_23'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_23'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_24'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_24'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_25'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_25'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_26'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_26'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_27'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_27'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_28'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_28'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_29'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_29'].material}
              />
              <mesh
                geometry={nodes['4134-6007_simplified_web_model_jason_30'].geometry}
                material={nodes['4134-6007_simplified_web_model_jason_30'].material}
              />
            </group>
            <group position={[0, -1.5, 2]}>
              <mesh
                geometry={nodes['VV152-01_simplified_web_model_jason_1'].geometry}
                material={nodes['VV152-01_simplified_web_model_jason_1'].material}
              />
            </group>
            <group position={[0.9, 2.6, 2.2]} rotation={[Math.PI, 0, -0.6]}>
              <mesh
                geometry={nodes['XSN04-15_simplified_web_model_jason_2'].geometry}
                material={nodes['XSN04-15_simplified_web_model_jason_2'].material}
              />
            </group>
            <group position={[-1.6, 1.1, 2.1]} rotation={[0, 0, 0.1]}>
              <mesh
                geometry={nodes['XSM20-05_simplified_web_model_jason_7'].geometry}
                material={nodes['XSM20-05_simplified_web_model_jason_7'].material}
              />
            </group>
            <group position={[2.6, 0.9, 2.2]} rotation={[Math.PI, 0, -0.4]}>
              <mesh
                geometry={nodes['XSN04-15_simplified_web_model_jason_3'].geometry}
                material={nodes['XSN04-15_simplified_web_model_jason_3'].material}
              />
            </group>
            <group position={[-1.6, -0.4, 1.3]} rotation={[Math.PI / 2, 0, -Math.PI / 2]}>
              <mesh
                geometry={nodes['VV028-01_SIDEPANEL_simplified_web_model_jason_3'].geometry}
                material={nodes['VV028-01_SIDEPANEL_simplified_web_model_jason_3'].material}
              />
            </group>
          </group>
          <group position={[0, 0.8, -1.2]} rotation={[Math.PI / 2, 0.6, 0]}>
            <mesh
              geometry={nodes['VV026-01_simplified_web_model_jason_1'].geometry}
              material={nodes['VV026-01_simplified_web_model_jason_1'].material}
            />
          </group>
          <group position={[0.6, 0.3, -1.9]} rotation={[0, -0.5, Math.PI]}>
            <mesh
              geometry={nodes['MDP-07_simplified_web_model_jason_4'].geometry}
              material={nodes['MDP-07_simplified_web_model_jason_4'].material}
            />
            <mesh
              geometry={nodes['MDP-07_simplified_web_model_jason_5'].geometry}
              material={nodes['MDP-07_simplified_web_model_jason_5'].material}
            />
          </group>
          <group position={[-4.4, 0, -13.4]} rotation={[0, 1.5, 0]}>
            <group position={[-9.3, 14.8, 1.7]} rotation={[-1.7, -0.1, 0.1]} scale={[1, 1, 1]}>
              <mesh
                geometry={nodes['4134-2006-1_simplified_web_model_jason_4'].geometry}
                material={nodes['4134-2006-1_simplified_web_model_jason_4'].material}
              />
            </group>
            <group position={[1.5, 1.2, -0.1]} rotation={[-Math.PI / 2, 0.1, Math.PI / 2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_6'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_7'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_7'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_8'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_8'].material}
              />
            </group>
            <group position={[3.3, 17.6, -0.1]} rotation={[-Math.PI / 2, 0.1, Math.PI / 2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_9'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_9'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_10'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_10'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_11'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_11'].material}
              />
            </group>
            <group position={[1.5, 11.9, -0.1]} rotation={[-Math.PI / 2, 0.1, Math.PI / 2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_12'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_12'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_13'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_13'].material}
              />
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_14'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_14'].material}
              />
            </group>
            <mesh
              geometry={nodes['4400-2008_simplified_web_model_jason_2'].geometry}
              material={nodes['4400-2008_simplified_web_model_jason_2'].material}
            />
            <group position={[-8.5, 3.5, 3.1]} rotation={[-1.7, -0.1, 0.1]} scale={[1, 1, 1]}>
              <mesh
                geometry={nodes['4134-2006-1_simplified_web_model_jason_5'].geometry}
                material={nodes['4134-2006-1_simplified_web_model_jason_5'].material}
              />
            </group>
          </group>
          <group position={[0, 0, -13.1]} rotation={[0, Math.PI / 2, 0]}>
            <group position={[-8.4, 3.5, 1.8]} rotation={[1.7, 0.1, 0]}>
              <mesh
                geometry={nodes['4134-2006-1_simplified_web_model_jason_8'].geometry}
                material={nodes['4134-2006-1_simplified_web_model_jason_8'].material}
              />
            </group>
            <group position={[1.8, 1.2, 4.4]} rotation={[-1.2, -1.4, -1.2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_18'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_18'].material}
              />
            </group>
            <group position={[1.8, 11.9, 4.4]} rotation={[-1.2, -1.4, -1.2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_19'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_19'].material}
              />
            </group>
            <group position={[3.6, 17.6, 4.2]} rotation={[-1.2, -1.4, -1.2]}>
              <mesh
                geometry={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_20'].geometry}
                material={nodes['BSO-632-14ZI_PEM_standoff_simplified_web_model_jason_20'].material}
              />
            </group>
            <group position={[-9.1, 14.8, 3.3]} rotation={[1.7, 0.1, 0]}>
              <mesh
                geometry={nodes['4134-2006-1_simplified_web_model_jason_9'].geometry}
                material={nodes['4134-2006-1_simplified_web_model_jason_9'].material}
              />
            </group>
            <group position={[0.3, 0, 4.5]} rotation={[0, 0.1, 0]}>
              <mesh
                geometry={nodes['4400-2007_simplified_web_model_jason_3'].geometry}
                material={nodes['4400-2007_simplified_web_model_jason_3'].material}
              />
            </group>
          </group>
        </group>
        <group position={[0, 7.3, 0]} rotation={[Math.PI / 2, 0, -Math.PI / 4]}>
          <mesh
            geometry={nodes['4200-413_web_model_jason_1'].geometry}
            material={nodes['4200-413_web_model_jason_1'].material}
          />
        </group>
        <group position={[0.9, 2.3, -0.9]} rotation={[-Math.PI, 0.2, Math.PI / 2]}>
          <mesh
            geometry={nodes['XSM30-48_web_model_jason_7'].geometry}
            material={nodes['XSM30-48_web_model_jason_7'].material}
          />
        </group>
        <group position={[1.6, 5.4, 0]} rotation={[-2.9, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes['4100-033_web_model_jason_9'].geometry}
            material={nodes['4100-033_web_model_jason_9'].material}
          />
        </group>
        <group position={[0, 7.1, 0]}>
          <mesh
            geometry={nodes['OO-13_web_model_jason_5'].geometry}
            material={nodes['OO-13_web_model_jason_5'].material}
          />
        </group>
        <group position={[1.7, 0.7, -0.5]} rotation={[Math.PI / 2, Math.PI / 2, 0]}>
          <group position={[0.1, 1.7, 0.2]} rotation={[0, 0, 0]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_8'].geometry}
              material={nodes['XSM30-35_web_model_jason_8'].material}
            />
          </group>
          <group position={[1.2, 0.1, 0.1]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_8'].geometry}
              material={nodes['XWM30-01_web_model_jason_8'].material}
            />
          </group>
          <group position={[0.3, 0.6, 0.4]} rotation={[Math.PI / 2, 1.5, 0]}>
            <mesh
              geometry={nodes['XUN10-01_web_model_jason_2'].geometry}
              material={nodes['XUN10-01_web_model_jason_2'].material}
            />
          </group>
          <group position={[0.3, 1.2, 0.4]} rotation={[Math.PI / 2, 0.8, 0]}>
            <mesh
              geometry={nodes['XUN10-01_web_model_jason_3'].geometry}
              material={nodes['XUN10-01_web_model_jason_3'].material}
            />
          </group>
          <group position={[0.2, 0.7, 0.6]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_2'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_2'].material}
            />
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_3'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_3'].material}
            />
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_4'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_4'].material}
            />
          </group>
          <group position={[0.1, 0.1, 0.2]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_9'].geometry}
              material={nodes['XSM30-35_web_model_jason_9'].material}
            />
          </group>
          <group position={[1.2, 0.1, 0.2]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_10'].geometry}
              material={nodes['XSM30-35_web_model_jason_10'].material}
            />
          </group>
          <group position={[1.2, 1.7, 0.2]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_11'].geometry}
              material={nodes['XSM30-35_web_model_jason_11'].material}
            />
          </group>
          <group position={[0.1, 0.1, 0.1]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_9'].geometry}
              material={nodes['XWM30-01_web_model_jason_9'].material}
            />
          </group>
          <mesh
            geometry={nodes['4200-2784_web_model_jason_1'].geometry}
            material={nodes['4200-2784_web_model_jason_1'].material}
          />
          <group position={[0.8, 0.9, 0.5]} rotation={[-Math.PI / 2, -Math.PI / 2, 0]}>
            <mesh
              geometry={nodes['MFD-22_web_model_jason_1'].geometry}
              material={nodes['MFD-22_web_model_jason_1'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_2'].geometry}
              material={nodes['MFD-22_web_model_jason_2'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_3'].geometry}
              material={nodes['MFD-22_web_model_jason_3'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_4'].geometry}
              material={nodes['MFD-22_web_model_jason_4'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_5'].geometry}
              material={nodes['MFD-22_web_model_jason_5'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_6'].geometry}
              material={nodes['MFD-22_web_model_jason_6'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_7'].geometry}
              material={nodes['MFD-22_web_model_jason_7'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_8'].geometry}
              material={nodes['MFD-22_web_model_jason_8'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_9'].geometry}
              material={nodes['MFD-22_web_model_jason_9'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_10'].geometry}
              material={nodes['MFD-22_web_model_jason_10'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_11'].geometry}
              material={nodes['MFD-22_web_model_jason_11'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_12'].geometry}
              material={nodes['MFD-22_web_model_jason_12'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_13'].geometry}
              material={nodes['MFD-22_web_model_jason_13'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_14'].geometry}
              material={nodes['MFD-22_web_model_jason_14'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_15'].geometry}
              material={nodes['MFD-22_web_model_jason_15'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_16'].geometry}
              material={nodes['MFD-22_web_model_jason_16'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_17'].geometry}
              material={nodes['MFD-22_web_model_jason_17'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_18'].geometry}
              material={nodes['MFD-22_web_model_jason_18'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_19'].geometry}
              material={nodes['MFD-22_web_model_jason_19'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_20'].geometry}
              material={nodes['MFD-22_web_model_jason_20'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_21'].geometry}
              material={nodes['MFD-22_web_model_jason_21'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_22'].geometry}
              material={nodes['MFD-22_web_model_jason_22'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_23'].geometry}
              material={nodes['MFD-22_web_model_jason_23'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_24'].geometry}
              material={nodes['MFD-22_web_model_jason_24'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_25'].geometry}
              material={nodes['MFD-22_web_model_jason_25'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_26'].geometry}
              material={nodes['MFD-22_web_model_jason_26'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_27'].geometry}
              material={nodes['MFD-22_web_model_jason_27'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_28'].geometry}
              material={nodes['MFD-22_web_model_jason_28'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_29'].geometry}
              material={nodes['MFD-22_web_model_jason_29'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_30'].geometry}
              material={nodes['MFD-22_web_model_jason_30'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_31'].geometry}
              material={nodes['MFD-22_web_model_jason_31'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_32'].geometry}
              material={nodes['MFD-22_web_model_jason_32'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_33'].geometry}
              material={nodes['MFD-22_web_model_jason_33'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_34'].geometry}
              material={nodes['MFD-22_web_model_jason_34'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_35'].geometry}
              material={nodes['MFD-22_web_model_jason_35'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_36'].geometry}
              material={nodes['MFD-22_web_model_jason_36'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_37'].geometry}
              material={nodes['MFD-22_web_model_jason_37'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_38'].geometry}
              material={nodes['MFD-22_web_model_jason_38'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_39'].geometry}
              material={nodes['MFD-22_web_model_jason_39'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_40'].geometry}
              material={nodes['MFD-22_web_model_jason_40'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_41'].geometry}
              material={nodes['MFD-22_web_model_jason_41'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_42'].geometry}
              material={nodes['MFD-22_web_model_jason_42'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_43'].geometry}
              material={nodes['MFD-22_web_model_jason_43'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_44'].geometry}
              material={nodes['MFD-22_web_model_jason_44'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_45'].geometry}
              material={nodes['MFD-22_web_model_jason_45'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_46'].geometry}
              material={nodes['MFD-22_web_model_jason_46'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_47'].geometry}
              material={nodes['MFD-22_web_model_jason_47'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_48'].geometry}
              material={nodes['MFD-22_web_model_jason_48'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_49'].geometry}
              material={nodes['MFD-22_web_model_jason_49'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_50'].geometry}
              material={nodes['MFD-22_web_model_jason_50'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_51'].geometry}
              material={nodes['MFD-22_web_model_jason_51'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_52'].geometry}
              material={nodes['MFD-22_web_model_jason_52'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_53'].geometry}
              material={nodes['MFD-22_web_model_jason_53'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_54'].geometry}
              material={nodes['MFD-22_web_model_jason_54'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_55'].geometry}
              material={nodes['MFD-22_web_model_jason_55'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_56'].geometry}
              material={nodes['MFD-22_web_model_jason_56'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_57'].geometry}
              material={nodes['MFD-22_web_model_jason_57'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_58'].geometry}
              material={nodes['MFD-22_web_model_jason_58'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_59'].geometry}
              material={nodes['MFD-22_web_model_jason_59'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_60'].geometry}
              material={nodes['MFD-22_web_model_jason_60'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_61'].geometry}
              material={nodes['MFD-22_web_model_jason_61'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_62'].geometry}
              material={nodes['MFD-22_web_model_jason_62'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_63'].geometry}
              material={nodes['MFD-22_web_model_jason_63'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_64'].geometry}
              material={nodes['MFD-22_web_model_jason_64'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_65'].geometry}
              material={nodes['MFD-22_web_model_jason_65'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_66'].geometry}
              material={nodes['MFD-22_web_model_jason_66'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_67'].geometry}
              material={nodes['MFD-22_web_model_jason_67'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_68'].geometry}
              material={nodes['MFD-22_web_model_jason_68'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_69'].geometry}
              material={nodes['MFD-22_web_model_jason_69'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_70'].geometry}
              material={nodes['MFD-22_web_model_jason_70'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_71'].geometry}
              material={nodes['MFD-22_web_model_jason_71'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_72'].geometry}
              material={nodes['MFD-22_web_model_jason_72'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_73'].geometry}
              material={nodes['MFD-22_web_model_jason_73'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_74'].geometry}
              material={nodes['MFD-22_web_model_jason_74'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_75'].geometry}
              material={nodes['MFD-22_web_model_jason_75'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_76'].geometry}
              material={nodes['MFD-22_web_model_jason_76'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_77'].geometry}
              material={nodes['MFD-22_web_model_jason_77'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_78'].geometry}
              material={nodes['MFD-22_web_model_jason_78'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_79'].geometry}
              material={nodes['MFD-22_web_model_jason_79'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_80'].geometry}
              material={nodes['MFD-22_web_model_jason_80'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_81'].geometry}
              material={nodes['MFD-22_web_model_jason_81'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_82'].geometry}
              material={nodes['MFD-22_web_model_jason_82'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_83'].geometry}
              material={nodes['MFD-22_web_model_jason_83'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_84'].geometry}
              material={nodes['MFD-22_web_model_jason_84'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_85'].geometry}
              material={nodes['MFD-22_web_model_jason_85'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_86'].geometry}
              material={nodes['MFD-22_web_model_jason_86'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_87'].geometry}
              material={nodes['MFD-22_web_model_jason_87'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_88'].geometry}
              material={nodes['MFD-22_web_model_jason_88'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_89'].geometry}
              material={nodes['MFD-22_web_model_jason_89'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_90'].geometry}
              material={nodes['MFD-22_web_model_jason_90'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_91'].geometry}
              material={nodes['MFD-22_web_model_jason_91'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_92'].geometry}
              material={nodes['MFD-22_web_model_jason_92'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_93'].geometry}
              material={nodes['MFD-22_web_model_jason_93'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_94'].geometry}
              material={nodes['MFD-22_web_model_jason_94'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_95'].geometry}
              material={nodes['MFD-22_web_model_jason_95'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_96'].geometry}
              material={nodes['MFD-22_web_model_jason_96'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_97'].geometry}
              material={nodes['MFD-22_web_model_jason_97'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_98'].geometry}
              material={nodes['MFD-22_web_model_jason_98'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_99'].geometry}
              material={nodes['MFD-22_web_model_jason_99'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_100'].geometry}
              material={nodes['MFD-22_web_model_jason_100'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_101'].geometry}
              material={nodes['MFD-22_web_model_jason_101'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_102'].geometry}
              material={nodes['MFD-22_web_model_jason_102'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_103'].geometry}
              material={nodes['MFD-22_web_model_jason_103'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_104'].geometry}
              material={nodes['MFD-22_web_model_jason_104'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_105'].geometry}
              material={nodes['MFD-22_web_model_jason_105'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_106'].geometry}
              material={nodes['MFD-22_web_model_jason_106'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_107'].geometry}
              material={nodes['MFD-22_web_model_jason_107'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_108'].geometry}
              material={nodes['MFD-22_web_model_jason_108'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_109'].geometry}
              material={nodes['MFD-22_web_model_jason_109'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_110'].geometry}
              material={nodes['MFD-22_web_model_jason_110'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_111'].geometry}
              material={nodes['MFD-22_web_model_jason_111'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_112'].geometry}
              material={nodes['MFD-22_web_model_jason_112'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_113'].geometry}
              material={nodes['MFD-22_web_model_jason_113'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_114'].geometry}
              material={nodes['MFD-22_web_model_jason_114'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_115'].geometry}
              material={nodes['MFD-22_web_model_jason_115'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_116'].geometry}
              material={nodes['MFD-22_web_model_jason_116'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_117'].geometry}
              material={nodes['MFD-22_web_model_jason_117'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_118'].geometry}
              material={nodes['MFD-22_web_model_jason_118'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_119'].geometry}
              material={nodes['MFD-22_web_model_jason_119'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_120'].geometry}
              material={nodes['MFD-22_web_model_jason_120'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_121'].geometry}
              material={nodes['MFD-22_web_model_jason_121'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_122'].geometry}
              material={nodes['MFD-22_web_model_jason_122'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_123'].geometry}
              material={nodes['MFD-22_web_model_jason_123'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_124'].geometry}
              material={nodes['MFD-22_web_model_jason_124'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_125'].geometry}
              material={nodes['MFD-22_web_model_jason_125'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_126'].geometry}
              material={nodes['MFD-22_web_model_jason_126'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_127'].geometry}
              material={nodes['MFD-22_web_model_jason_127'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_128'].geometry}
              material={nodes['MFD-22_web_model_jason_128'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_129'].geometry}
              material={nodes['MFD-22_web_model_jason_129'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_130'].geometry}
              material={nodes['MFD-22_web_model_jason_130'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_131'].geometry}
              material={nodes['MFD-22_web_model_jason_131'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_132'].geometry}
              material={nodes['MFD-22_web_model_jason_132'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_133'].geometry}
              material={nodes['MFD-22_web_model_jason_133'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_134'].geometry}
              material={nodes['MFD-22_web_model_jason_134'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_135'].geometry}
              material={nodes['MFD-22_web_model_jason_135'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_136'].geometry}
              material={nodes['MFD-22_web_model_jason_136'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_137'].geometry}
              material={nodes['MFD-22_web_model_jason_137'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_138'].geometry}
              material={nodes['MFD-22_web_model_jason_138'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_139'].geometry}
              material={nodes['MFD-22_web_model_jason_139'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_140'].geometry}
              material={nodes['MFD-22_web_model_jason_140'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_141'].geometry}
              material={nodes['MFD-22_web_model_jason_141'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_142'].geometry}
              material={nodes['MFD-22_web_model_jason_142'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_143'].geometry}
              material={nodes['MFD-22_web_model_jason_143'].material}
            />
            <mesh
              geometry={nodes['MFD-22_web_model_jason_144'].geometry}
              material={nodes['MFD-22_web_model_jason_144'].material}
            />
          </group>
          <group position={[0.2, 1.3, 0.6]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_5'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_5'].material}
            />
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_6'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_6'].material}
            />
            <mesh
              geometry={nodes['MFD-04_madmile_web_model_jason_7'].geometry}
              material={nodes['MFD-04_madmile_web_model_jason_7'].material}
            />
          </group>
          <group position={[0.1, 1.7, 0.1]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_10'].geometry}
              material={nodes['XWM30-01_web_model_jason_10'].material}
            />
          </group>
          <group position={[1.2, 1.7, 0.1]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_11'].geometry}
              material={nodes['XWM30-01_web_model_jason_11'].material}
            />
          </group>
        </group>
        <group position={[0, 5.4, 1.7]} rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
          <mesh
            geometry={nodes['OO-13_web_model_jason_6'].geometry}
            material={nodes['OO-13_web_model_jason_6'].material}
          />
        </group>
        <group position={[1.7, 5.4, 0]} rotation={[-Math.PI, 0, Math.PI / 2]}>
          <mesh
            geometry={nodes['OO-13_web_model_jason_7'].geometry}
            material={nodes['OO-13_web_model_jason_7'].material}
          />
        </group>
        <group position={[0, 5.4, -1.7]} rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
          <mesh
            geometry={nodes['OO-13_web_model_jason_8'].geometry}
            material={nodes['OO-13_web_model_jason_8'].material}
          />
        </group>
        <group position={[-1.7, 5.4, 0]} rotation={[0, 0, -Math.PI / 2]}>
          <mesh
            geometry={nodes['OO-13_web_model_jason_9'].geometry}
            material={nodes['OO-13_web_model_jason_9'].material}
          />
        </group>
        <group position={[0.9, 2.5, 1.3]} rotation={[0, 0, Math.PI / 2]}>
          <group position={[-1.7, 1.4, 0.5]} rotation={[0, 0, 0]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_12'].geometry}
              material={nodes['XSM30-35_web_model_jason_12'].material}
            />
          </group>
          <group position={[-0.7, -0.2, 0.4]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_12'].geometry}
              material={nodes['XWM30-01_web_model_jason_12'].material}
            />
          </group>
          <group position={[-1.7, 1.4, 0.4]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_13'].geometry}
              material={nodes['XWM30-01_web_model_jason_13'].material}
            />
          </group>
          <group position={[-1.8, -0.3, 0.3]}>
            <group position={[0.5, 1.3, 0.3]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes['MFD-04_web_model_jason_2'].geometry}
                material={nodes['MFD-04_web_model_jason_2'].material}
              />
              <mesh
                geometry={nodes['MFD-04_web_model_jason_3'].geometry}
                material={nodes['MFD-04_web_model_jason_3'].material}
              />
              <mesh
                geometry={nodes['MFD-04_web_model_jason_4'].geometry}
                material={nodes['MFD-04_web_model_jason_4'].material}
              />
            </group>
            <group position={[0.5, 0.7, 0.3]} rotation={[Math.PI / 2, 0, 0]}>
              <mesh
                geometry={nodes['MFD-04_web_model_jason_5'].geometry}
                material={nodes['MFD-04_web_model_jason_5'].material}
              />
              <mesh
                geometry={nodes['MFD-04_web_model_jason_6'].geometry}
                material={nodes['MFD-04_web_model_jason_6'].material}
              />
              <mesh
                geometry={nodes['MFD-04_web_model_jason_7'].geometry}
                material={nodes['MFD-04_web_model_jason_7'].material}
              />
            </group>
            <mesh
              geometry={nodes['4200-2714_web_model_jason_1'].geometry}
              material={nodes['4200-2714_web_model_jason_1'].material}
            />
          </group>
          <group position={[-0.7, 1.4, 0.5]} rotation={[0, 0, 0]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_13'].geometry}
              material={nodes['XSM30-35_web_model_jason_13'].material}
            />
          </group>
          <group position={[-1.7, -0.2, 0.5]} rotation={[0, 0, -0.3]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_14'].geometry}
              material={nodes['XSM30-35_web_model_jason_14'].material}
            />
          </group>
          <group position={[-0.7, -0.2, 0.5]} rotation={[0, 0, -0.6]}>
            <mesh
              geometry={nodes['XSM30-35_web_model_jason_15'].geometry}
              material={nodes['XSM30-35_web_model_jason_15'].material}
            />
          </group>
          <group position={[-0.7, 1.4, 0.4]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_14'].geometry}
              material={nodes['XWM30-01_web_model_jason_14'].material}
            />
          </group>
          <group position={[-1.7, -0.2, 0.4]}>
            <mesh
              geometry={nodes['XWM30-01_web_model_jason_15'].geometry}
              material={nodes['XWM30-01_web_model_jason_15'].material}
            />
          </group>
        </group>
      </group>
      <PerspectiveCamera
        makeDefault={false}
        far={83.2}
        near={50.8}
        fov={39.6}
        position={[64.7, 29.9, 6.5]}
        rotation={[-1.3, 1.2, 1.3]}
        scale={[1, 1, 1]}
      />
    </group>
  )
}

useGLTF.preload('/CryoAdvance 3D Web File Concept.glb')
